






















import {Component, Vue, Watch} from "vue-property-decorator";
import TimeSeriesChart from "@/components/controlpanel/statistics/TimeSeriesChart.vue";
import DateInput from "../form/DateInput.vue";
import axios from "axios";
import moment from "moment";
import FormButton from "@/components/form/Button.vue";
import {chartJSDataToCSV} from "@/utilities/statistics";

@Component({
  components: {
    FormButton,
    DateInput,
    TimeSeriesChart,
  },
})
export default class RegistrationStatistics extends Vue {
  private registrationData: any;
  private loaded = false;
  private canvas: any = null;
  private chartOptions = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        type: "time",
        distribution: "linear",
      }],
      yAxes: [{
        id: "left-y-axis",
        type: "linear",
        position: "left",
      }, {
        id: "right-y-axis",
        type: "linear",
        position: "right",
      }],
    },
  };

  private startDate = new Date().setFullYear(new Date().getFullYear() - 1);
  private endDate = new Date();

  public mounted() {
    this.refresh();
  }

  public refresh() {
    this.loaded = false;
    axios.get("/controlpanel/statistics/users/registrations", {
      params: {
        start_date: moment(this.startDate).format("YYYY-MM-DD"),
        end_date: moment(this.endDate).format("YYYY-MM-DD"),
      },
    }).then((res: any) => {
        const {before_start_date, counts} = res.data;
        const newRegistrations = counts.map(({count, day}: any) => ({x: day, y: parseInt(count, 10)}));
        const cumulativeSum = ((sum: number) =>
          ({x, y}: { x: string, y: number }) => {
            sum += y;
            return {x, y: sum};
          });
        const cumulativeRegistrations = newRegistrations.map(cumulativeSum(parseInt(before_start_date, 10)));
        this.registrationData = {
          datasets: [
            {
              data: newRegistrations,
              label: "New registrations",
              backgroundColor: "#2abdd0",
              fill: false,
              yAxisID: "left-y-axis",
              type: "bar",
            },
            {
              data: cumulativeRegistrations,
              label: "Total amount of users",
              borderColor: "#35d02a",
              fill: false,
              yAxisID: "right-y-axis",
            },
          ],
        };
        this.loaded = true;
      },
    );
  }

  @Watch("startDate")
  private startDateChanged() {
    this.refresh();
  }

  @Watch("endDate")
  private endDateChanged() {
    this.refresh();
  }

  private chartRendered(canvas: any) {
    this.canvas = canvas;
  }

  private downloadImage() {
    const imageData = this.canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.download = "registrations.png";
    link.href = imageData;
    link.click();
  }

  private downloadCSV() {
    const csvContent = chartJSDataToCSV(this.registrationData);
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "registration_statistics.csv");
    document.body.appendChild(link);

    link.click();
  }
}
